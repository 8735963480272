import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = [];

export const dataArraySlice = createSlice({
    name: "dataArray",
    initialState: { value: initialStateValue },
    reducers: {
        setDataArray: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { setDataArray } = dataArraySlice.actions;

export default dataArraySlice.reducer;