import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { validate } from 'email-validator';
import { useSelector } from 'react-redux';

// import { storeBucketJSON } from '../../services/application-service';

import FrequencyText from '../FrequencyText';
import SubmitButton from '../SubmitButton';
// import { getActiveUsers } from '../../services/analytics-service';


const Alert = ({ t, openAlert, setOpenAlert, setExistingSchedules, getExistingSchedules }) => {

    const period = useSelector((state) => state.period.value);

    const email = useSelector((state) => state.email.value);

    const dataArray = useSelector((state) => state.dataArray.value);

    const [variant, setVariant] = useState('system');

    useEffect(() => {
        if (validate(email)) {
            setInvalidEmailMessage(null);
        } else {
            setVariant('error');
            setInvalidEmailMessage(t('warnings.invalidEmail'));
        }

        if (dataArray.length > 0) {
            setInvalidDataMessage(null);
        } else {
            setVariant('error');
            setInvalidDataMessage(t('warnings.invalidData'));
        }

        if (period.value.length === 0 || (period.value === 'customPeriod' && !period.customPeriod.unit.length)) {
            setVariant('error');
            setInvalidPeriodMessage(t('warnings.invalidPeriod'));
        } else {
            setInvalidPeriodMessage(null);
        }

        if (!invalidEmailMessage && !invalidPeriodMessage && (!invalidDataMessage || !invalidDataMessage.length)) {
            setVariant('system');
        }

    }, [period, email, dataArray]);

    const [invalidEmailMessage, setInvalidEmailMessage] = useState('');
    const [invalidPeriodMessage, setInvalidPeriodMessage] = useState('');
    const [invalidDataMessage, setInvalidDataMessage] = useState('');

    return (
        <bds-alert open={openAlert} variant={variant}>
            <bds-alert-header variant={variant} icon="info">
                {
                    variant === 'system' &&
                    <h3>{t('warnings.confirmData')}</h3>
                }
                {
                    variant === 'error' &&
                    <h2>{t('warnings.warning')}</h2>
                }
            </bds-alert-header>
            <bds-alert-body>
                {
                    invalidEmailMessage &&
                    <p className='warning'>{invalidEmailMessage}</p>
                }
                {
                    invalidDataMessage &&
                    <p className='warning'>{invalidDataMessage}</p>
                }
                {
                    invalidPeriodMessage &&
                    <p className='warning'>{invalidPeriodMessage}</p>
                }
                {
                    variant === 'system' &&
                    <div>
                        <FrequencyText t={t} period={period}/>
                        <p><strong>{t('recipientEmail')}</strong> {email}</p>
                        <p><strong>{t('dataToBeIncluded')}</strong> {dataArray.join(', ')}</p>
                    </div>
                }
            </bds-alert-body>
            <bds-alert-actions>
                <bds-button variant="delete" bold="true" onClick={() => setOpenAlert(false)}>{t('button.cancel')}</bds-button>
                {
                    variant === 'system' &&
                    <SubmitButton t={t} setOpenAlert={setOpenAlert} setExistingSchedules={setExistingSchedules} getExistingSchedules={getExistingSchedules} />
                }
            </bds-alert-actions>
        </bds-alert>
    );
};

Alert.propTypes = {
    t: PropTypes.func,
    openAlert: PropTypes.bool,
    setOpenAlert: PropTypes.func,
    setExistingSchedules: PropTypes.func,
    getExistingSchedules: PropTypes.func
};

export default Alert;