import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = '';

export const emailSlice = createSlice({
    name: "email",
    initialState: { value: initialStateValue },
    reducers: {
        setEmail: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { setEmail } = emailSlice.actions;

export default emailSlice.reducer;