import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setPeriod } from '../../store/period';

const FrequencyText = ({ t, period }) => {
    const dispatch = useDispatch();

    const [frequencyText, setFrequencyText] = useState('');

    useEffect(() => {
        switch (period.value) {
            case 'customPeriod':
                if (period.customPeriod.number > 1) {
                    if (period.customPeriod.unit === 'weeks') {
                        dispatch(setPeriod({ ...period, stringValue: `${t('lastFemPlural')} ${period.customPeriod.number} ${t(period.customPeriod.unit)} (${t('every')} ${period.customPeriod.number} ${t(period.customPeriod.unit)}) ` }));
                        setFrequencyText(`${t('lastFemPlural')} ${period.customPeriod.number} ${t(period.customPeriod.unit)} (${t('every')} ${period.customPeriod.number} ${t(period.customPeriod.unit)}) `);
                    }
                    else {
                        dispatch(setPeriod({ ...period, stringValue: `${t('lastPlural')} ${period.customPeriod.number} ${t(period.customPeriod.unit)} (${t('every')} ${period.customPeriod.number} ${t(period.customPeriod.unit)}) ` }));
                        setFrequencyText(`${t('lastPlural')} ${period.customPeriod.number} ${t(period.customPeriod.unit)} (${t('every')} ${period.customPeriod.number} ${t(period.customPeriod.unit)}) `);
                    }
                } else if (period.customPeriod.unit === 'weeks') {
                    dispatch(setPeriod({ ...period, stringValue: `${t('lastFem')} ${t(period.customPeriod.unit.slice(0, -1))} (${t('every')} ${period.customPeriod.number} ${t(period.customPeriod.unit).slice(0, -1)}) ` }));
                    setFrequencyText(`${t('lastFem')} ${t(period.customPeriod.unit.slice(0, -1))} (${t('every')} ${period.customPeriod.number} ${t(period.customPeriod.unit).slice(0, -1)}) `);
                } else {
                    dispatch(setPeriod({ ...period, stringValue: `${t('lastMasc')} ${t(period.customPeriod.unit.slice(0, -1))} (${t('every')} ${period.customPeriod.number} ${t(period.customPeriod.unit).slice(0, -1)}) ` }));
                    setFrequencyText(`${t('lastMasc')} ${t(period.customPeriod.unit.slice(0, -1))} (${t('every')} ${period.customPeriod.number} ${t(period.customPeriod.unit).slice(0, -1)}) `);
                }
                break;
            case 'today':
                dispatch(setPeriod({ ...period, stringValue: t('todayDaily') }));
                setFrequencyText(t('todayDaily'));
                break;
            case 'yesterday':
                dispatch(setPeriod({ ...period, stringValue: t('yesterdayDaily') }));
                setFrequencyText(t('yesterdayDaily'));
                break;
            case 'thisWeek':
                dispatch(setPeriod({ ...period, stringValue: t('thisWeek') }));
                setFrequencyText(t('thisWeek'));
                break;
            case 'lastMonth':
                dispatch(setPeriod({ ...period, stringValue: t('lastMonth') }));
                setFrequencyText(t('lastMonth'));
                break;
            default:
                dispatch(setPeriod({ ...period, stringValue: 'something went wrong' }));
        }

    }, [period.customPeriod, period.value]);

    return (
        <p><strong>{t('frequency')}</strong> {frequencyText}</p>
    );
};

FrequencyText.propTypes = {
    t: PropTypes.func,
    period: PropTypes.object
};

export default FrequencyText;