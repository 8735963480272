import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = [];

export const existingSchedulesSlice = createSlice({
    name: "existingSchedules",
    initialState: { value: initialStateValue },
    reducers: {
        setExistingSchedules: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { setExistingSchedules } = existingSchedulesSlice.actions;

export default existingSchedulesSlice.reducer;